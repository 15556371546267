import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import Routes
import { lazy, Suspense } from "react";
const NotFound = lazy(() => import("./pages/NotFound"));
const Home = lazy(() => import("./pages/Home"));
const Register = lazy(() => import("./pages/Register"));
const Login = lazy(() => import("./pages/Login"));
const OtpVerify = lazy(() => import("./pages/OtpVerify"));
const Editprofile = lazy(() => import("./pages/Editprofile"));
const Membership = lazy(() => import("./pages/Membership"));
const Securitypage = lazy(() => import("./pages/Securitypage"));
const Search = lazy(() => import("./pages/Search"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Events = lazy(() => import("./pages/Events"));
const AllEvents = lazy(() => import("./pages/AllEvents"));
const Singleevent = lazy(() => import("./pages/Singleevent"));
const SingleSearch = lazy(() => import("./pages/SingleSearch"));
const Logout = lazy(() => import("./pages/Logout"));
const AllFriends = lazy(() => import("./pages/AllFriends"));
const Friendsearch = lazy(() => import("./pages/Friendsearch"));
const FriendUser = lazy(() => import("./pages/FriendUser"));
const LatestNews = lazy(() => import("./pages/LatestNews"));
const Gallery = lazy(() => import("./pages/Gallery"));
const Allgallery = lazy(() => import("./pages/Allgallery"));
const PrivateMedia = lazy(() => import("./pages/PrivateMedia"));
const Previewalbum = lazy(() => import("./pages/Previewalbum"));
const Groups = lazy(() => import("./pages/Groups"));
const AllGroup = lazy(() => import("./pages/AllGroup"));
const Singlegroup = lazy(() => import("./pages/Singlegroup"));
const ReceivedMessages = lazy(() => import("./pages/ReceivedMessages"));
const SendMessages = lazy(() => import("./pages/SendMessages"));
const ReceivedMessagesChat = lazy(() => import("./pages/ReceivedMessagesChat"));
const AllSpeeddate = lazy(() => import("./pages/AllSpeeddate"));
const Speeddate = lazy(() => import("./pages/Speeddate"));
const AllForums = lazy(() => import("./pages/AllForums"));
const AllForumsall = lazy(() => import("./pages/AllForumsall"));
const Frontpageforum = lazy(() => import("./pages/Frontpageforum"));
const SingleForums = lazy(() => import("./pages/SingleForums"));
const SingleSpeed = lazy(() => import("./pages/SingleSpeed"));
const Forums = lazy(() => import("./pages/Forums"));
const Forumsmy = lazy(() => import("./pages/Forumsmy"));
const Setting = lazy(() => import("./pages/Setting"));
const Helpsupport = lazy(() => import("./pages/Helpsupport"));
const Groupimages = lazy(() => import("./pages/Groupimages"));
const Eventimages = lazy(() => import("./pages/Eventimages"));
const AllSearchpage = lazy(() => import("./pages/search/AllSearchpage"));
const Searchgroup = lazy(() => import("./pages/search/Searchgroup"));
const Searchmembers = lazy(() => import("./pages/search/Searchmembers"));
const Searchspeeddate = lazy(() => import("./pages/search/Searchspeeddate"));
const Searchpost = lazy(() => import("./pages/search/Searchpost"));
const Searchevent = lazy(() => import("./pages/search/Searchevent"));
const Searchforum = lazy(() => import("./pages/search/Searchforum"));
const Searchpeople = lazy(() => import("./pages/search/Searchpeople"));
const GalleryMedia = lazy(() => import("./pages/GalleryMedia"));
const Privacypolicy = lazy(() => import("./pages/Privacypolicy"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));

// Admin Pages
const ForgotPasswordAdmin = lazy(() => import("./pages/Admin/ForgotPassword"));
const AdminLogin = lazy(() => import("./pages/Admin/Login"));
const AdminUsers = lazy(() => import("./pages/Admin/Users"));
const ContentManagementAdmin = lazy(() =>
  import("./pages/Admin/ContentManagement")
);
const PaymentSubscriptionAdmin = lazy(() =>
  import("./pages/Admin/PaymentSubscription")
);
const PaymentRefundAdmin = lazy(() => import("./pages/Admin/PaymentRefund"));
const PaymentDisputeAdmin = lazy(() => import("./pages/Admin/PaymentDispute"));
const SecurityPrivacyAdmin = lazy(() =>
  import("./pages/Admin/SecurityPrivacy")
);
const Media = lazy(() => import("./pages/Admin/Media"));
const Group = lazy(() => import("./pages/Admin/Groups"));
const AdminForum = lazy(() => import("./pages/Admin/Forum"));
const Messaging = lazy(() => import("./pages/Admin/Messaging"));
const Timeline = lazy(() => import("./pages/Timeline"));
const AdminDashboard = lazy(() => import("./pages/Admin/Dashboard"));
const ReportViolation = lazy(() => import("./pages/Admin/ReportViolation"));
const AdminUsersProfile = lazy(() => import("./pages/Admin/UsersProfile"));
const AdminUsersDetail = lazy(() => import("./pages/Admin/UsersDetail"));
const Sitespeed = lazy(() => import("./pages/Admin/Sitespeed"));
const AdminPrivacyUpdate = lazy(() => import("./pages/Admin/Privacyupdate"));
const AdminUserfriendList = lazy(() => import("./pages/Admin/UsersFriendlist"));
const AdminUsersEvents = lazy(() => import("./pages/Admin/UsersEvents"));
const AdminUsersGroups = lazy(() => import("./pages/Admin/UsersGroups"));
const AdminViewgroupPost = lazy(() => import("./pages/Admin/ViewgroupPost"));
const AdminUsersSentmessage = lazy(() =>
  import("./pages/Admin/UsersSentmessage")
);
const AdminRole = lazy(() => import("./pages/Admin/AdminRole"));
const AdminLocationbaseusers = lazy(() =>
  import("./pages/Admin/Locationbaseusers")
);
const AdminSendNotification = lazy(() =>
  import("./pages/Admin/SendNotification")
);
const AdminUserEventsPost = lazy(() => import("./pages/Admin/UsersEventsPost"));
const AdminUserGroupsPost = lazy(() => import("./pages/Admin/UsersGroupsPost"));
const AdminUserGallery = lazy(() => import("./pages/Admin/UsersGallery"));
const AdminUserForum = lazy(() => import("./pages/Admin/UsersForum"));
const AdminLogsActivity = lazy(() => import("./pages/Admin/LogsActivity"));
const AdminUsersgdpr = lazy(() => import("./pages/Admin/Usersgdpr"));

function App() {
  return (
    <div className="App">
      <Router>
        <Suspense
          fallback={
            <div class="d-flex justify-content-center align-items-center min-vh-100">
              <div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          }
        >
          <Routes>
            <Route path="/timeline" element={<Timeline />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/securitypage" element={<Securitypage />} />
            <Route path="/privatealbum/:id" element={<PrivateMedia />} />
            <Route path="/previewimages/:id" element={<Previewalbum />} />
            <Route path="/otpverify" element={<OtpVerify />} />
            <Route path="/editprofile" element={<Editprofile />} />
            <Route path="/membership" element={<Membership />} />
            <Route path="/search" element={<Search />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/latestnews" element={<LatestNews />} />
            <Route path="/events" element={<Events />} />
            <Route path="/allevents" element={<AllEvents />} />
            <Route path="/singleevent/:slug" element={<Singleevent />} />
            <Route path="/SingleSearch/:id" element={<SingleSearch />} />
            <Route path="/friends" element={<AllFriends />} />
            <Route path="/friendsearch" element={<Friendsearch />} />
            <Route path="/friend/:id" element={<FriendUser />} />
            <Route path="/gallery/" element={<Gallery />} />
            <Route path="/allgallery/" element={<Allgallery />} />
            <Route path="/groups" element={<Groups />} />
            <Route path="/allgroup" element={<AllGroup />} />
            <Route path="/group/:slug" element={<Singlegroup />} />
            <Route path="/receivedmessages" element={<ReceivedMessages />} />
            <Route path="/sendmessages" element={<SendMessages />} />
            <Route
              path="/receivedchatmessage/:slug"
              element={<ReceivedMessagesChat />}
            />
            <Route path="/allspeeddate" element={<AllSpeeddate />} />
            <Route path="/speeddate" element={<Speeddate />} />
            {/* <Route path="/allforums" element={<AllForumsall />} /> */}

            <Route path="/allforums/:slug" element={<AllForums />} />
            <Route path="/allforums/" element={<Frontpageforum />} />

            <Route path="/forums" element={<Forumsmy />} />
            <Route path="/forums/:slug" element={<Forums />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/singleforums/:slug" element={<SingleForums />} />
            <Route path="/singledate/:slug" element={<SingleSpeed />} />
            <Route path="/helpsupport" element={<Helpsupport />} />
            {/* <Route path="/payment" element={<Payment />} /> */}
            <Route path="/groupmedia/:slug" element={<Groupimages />} />
            <Route path="/eventmedia/:slug" element={<Eventimages />} />
            <Route path="/media/:id" element={<GalleryMedia />} />
            <Route path="/privacy-policy" element={<Privacypolicy />} />
            <Route path="/searchtop" element={<AllSearchpage />} />
            <Route path="/searchgroup/" element={<Searchgroup />} />
            <Route path="/searchmember/" element={<Searchmembers />} />
            <Route path="/searchspeeddate/" element={<Searchspeeddate />} />
            <Route path="/searchpost/" element={<Searchpost />} />
            <Route path="/searchevent/" element={<Searchevent />} />
            <Route path="/searchforum/" element={<Searchforum />} />
            <Route path="/Searchpeople/" element={<Searchpeople />} />

            <Route path="/admin/users" element={<AdminUsers />} />
            <Route
              path="/admin/contentmanagement"
              element={<ContentManagementAdmin />}
            />
            <Route
              path="/admin/paymenthistory/:slug"
              element={<PaymentSubscriptionAdmin />}
            />
            <Route
              path="/admin/paymentrefund/:slug"
              element={<PaymentRefundAdmin />}
            />
            <Route
              path="/admin/paymentdispute/:slug"
              element={<PaymentDisputeAdmin />}
            />
            <Route
              path="/admin/securityprivacy"
              element={<SecurityPrivacyAdmin />}
            />
            <Route path="/admin/media" element={<Media />} />
            <Route path="/admin/groups" element={<Group />} />
            <Route path="/admin/forum" element={<AdminForum />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin/messaging" element={<Messaging />} />
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route
              path="/admin/reportviolation"
              element={<ReportViolation />}
            />

            <Route
              path="/admin/usersdetails/:slug"
              element={<AdminUsersDetail />}
            />
            <Route
              path="/admin/usersprofile/:slug"
              element={<AdminUsersProfile />}
            />
            <Route path="/admin/sitespeed" element={<Sitespeed />} />
            <Route
              path="/admin/privacyupdate"
              element={<AdminPrivacyUpdate />}
            />
            <Route
              path="/admin/userfriendlist/:slug"
              element={<AdminUserfriendList />}
            />
            <Route
              path="/admin/userevents/:slug"
              element={<AdminUsersEvents />}
            />
            <Route
              path="/admin/usergroups/:slug"
              element={<AdminUsersGroups />}
            />
            <Route
              path="/admin/groups/viewgrouppost/:slug"
              element={<AdminViewgroupPost />}
            />
            <Route
              path="/admin/usersentmessage/:slug"
              element={<AdminUsersSentmessage />}
            />
            <Route path="/admin/adminlist" element={<AdminRole />} />
            <Route
              path="/admin/locationbaseuser"
              element={<AdminLocationbaseusers />}
            />
            <Route
              path="/admin/sendnotification"
              element={<AdminSendNotification />}
            />
            <Route
              path="/admin/userevents/:slug/:id"
              element={<AdminUserEventsPost />}
            />
            <Route
              path="/admin/usergroups/:slug/:id"
              element={<AdminUserGroupsPost />}
            />
            <Route
              path="/admin/usergallery/:slug"
              element={<AdminUserGallery />}
            />
            <Route path="/admin/userforum/:slug" element={<AdminUserForum />} />
            <Route path="/admin/logsactivity" element={<AdminLogsActivity />} />
            <Route path="/admin/usersgdpr" element={<AdminUsersgdpr />} />
            <Route path="/forgot-pasword" element={<ForgotPassword />} />
            <Route
              path="/admin/forgot-pasword"
              element={<ForgotPasswordAdmin />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
